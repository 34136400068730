<template>
  <div ref="root">
    <div class="mainPage" v-if="!loading">
      <!-- NAVBAR -->
      <NavBar class="fixed w-full top-0 left-0 z-10 bg-black" v-if="!$route.path.includes('/seleccionar-complejo')">
        <!-- logo -->
        <router-link to="/" class="h-full flex-none flex gap-4 items-center">
          <div class="logo h-full aspect-video flex gap-4 items-center" v-if="$store.state.cinemaInfo.logo">
            <img :src="$store.state.cinemaInfo.logo" alt="Logo" class="h-full w-fit object-contain object-left">
            <div class="divider w-0 h-8 bg-white border-r" v-if="$store.state.cinemas.length > 1"></div>
            <p class="text-white font-bold uppercase text-sm flex-none" v-if="$store.state.cinemas.length > 1">
              {{ $store.state.cinemaInfo.nombre }}
            </p>
          </div>

          <p v-else class="text-white font-bold uppercase text-sm flex-none">
            {{ $store.state.cinemaInfo.nombre }}
          </p>
        </router-link>

        <!-- social -->
        <div class="social flex gap-4" v-if="!$store.state.kioskMode">
          <!-- Facebook -->
          <a v-if="$store.state.cinemaInfo.facebook" :href="$store.state.cinemaInfo.facebook">
            <img class="w-5 h-5" src="/assets/icons/facebook-brands.svg" alt="facebook" />
          </a>
          <!-- Twitter -->
          <a v-if="$store.state.cinemaInfo.twitter" :href="$store.state.cinemaInfo.twitter">
            <img class="w-5 h-5" src="/assets/icons/twitter-brands.svg" alt="twitter" />
          </a>
          <!-- Instagram -->
          <a v-if="$store.state.cinemaInfo.instagram" :href="$store.state.cinemaInfo.instagram">
            <img class="w-5 h-5" src="/assets/icons/instagram-brands.svg" alt="instagram" />
          </a>
        </div>

        <!-- clock -->
        <div class="clock flex gap-2 select-none" v-if="$store.state.kioskMode" @click="openKioskMenu()">
          <!--p class="text-white text-2xl">
            {{ dateTime.hours }}:{{ dateTime.minutes }}
          </p-->
          <img src="/assets/icons/settings.svg" alt="fecha" class="h-6" />
        </div>
      </NavBar>

      <!-- MAIN -->
      <main class="text-white min-h-screen" :class="!$route.path.includes('/seleccionar-complejo') ? 'pt-20' : ''">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <!--keep-alive exclude="Pagar"-->
            <component :is="Component" />
            <!--/keep-alive-->
          </transition>
        </router-view>
      </main>

      <!-- FOOTER WEB -->
      <section class="footer bg-neutral-900" v-if="!$store.state.kioskMode"
        v-show="$route.path != '/seleccionar-complejo'">
        <div class="footerContainer p-10 flex flex-col md:flex-row gap-8 items-center justify-between">
          <div class="
              cinema-info
              flex flex-col
              gap-4
              items-center
              md:flex-row
            ">
            <!-- logo -->
            <div class="logo-container md:pr-4 md:border-r">
              <img v-if="$store.state.cinemaInfo.logo" :src="$store.state.cinemaInfo.logo" alt="Logo" class="h-8" />
              <p v-else class="text-white text-xl font-bold">
                {{ $store.state.cinemaInfo.nombre }}
              </p>
            </div>
            <!-- location -->
            <div class="location text-center md:text-left">
              <p class="text-white">{{ $store.state.cinemaInfo.direccion }}</p>
              <p class="text-white">
                {{ $store.state.cinemaInfo.localidad }},
                {{ $store.state.cinemaInfo.provincia }}
              </p>
            </div>
          </div>

          <div class="developer flex gap-8 text-center text-white">
            <div class="flex flex-col gap-2 items-center">
              <span class="text-xs">Desarrollado por</span>
              <a href="https://adro.studio" class="text-xl opacity-25">adro.studio</a>
            </div>
            <div class="flex flex-col gap-2 items-center">
              <span class="text-xs">Powered by</span>
              <img class="h-8" src="/assets/img/logo_gaf.svg" alt="">
            </div>
          </div>
        </div>

        <div class="version text-center text-white p-2 bg-black">
          <p class=" text-xs">v{{ $store.state.webAppVersion }}</p>
        </div>
      </section>

      <!-- KIOSK MENU -->
      <KioskMenu v-if="$store.state.kioskMode" ref="kioskMenu" />
    </div>

    <!-- LOADING -->
    <div class="loading h-screen flex items-center justify-center" v-else>
      <Spinner />
    </div>

    <!-- PWA UPDATES-->
    <div
      class="update py-4 fixed top-0 left-0 bg-primary-900 w-full h-full text-white bg-black flex flex-col gap-4 p-8 items-center justify-center z-50"
      v-if="updateExists">
      <div class="text text-center space-y-8">
        <Spinner></Spinner>
        <p class="font-bold">Aguarde un momento, por favor</p>
      </div>
    </div>

    <!-- TOAST FOR ELECTRON UPDATES -->
    <Toast ref="toast" />
  </div>
</template>

<script lang="ts">
//update
import update from './update'

// utilities
import utilities from "./utilities"

// custom components
import Spinner from "@/components/Spinner.vue"
import NavBar from "@/components/NavBar.vue"
import Button from "@/components/Button.vue"
import KioskMenu from "@/components/KioskMenu.vue"
import Toast from "@/components/Toast.vue"
import { CustomVue } from "vuex";

//set date
const date = new Date();

//this component
import { defineComponent } from "vue";

//enable kiosk config menu
let kioskMenuCounter = 0;

export default defineComponent({
  name: "AppView",
  components: {
    Spinner,
    NavBar,
    Button,
    KioskMenu,
    Toast,
  },

  mixins: [update],

  data() {
    return {
      loading: true,
      cinemas: [] as any[any],
      dateTime: {
        hours: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minutes:
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      }
    };
  },

  async created() {
    //get config
    let cinemaConfig = {} as any[any]

    //KIOSK MODE
    if (this.$store.state.kioskMode && !this.$store.state.devMode && !this.$route.params.cinemaId) {
      //set electron api
      const { electron } = window as any

      //get config from electron
      electron.send("getConfig")
      electron.on("getConfig", (config: any[any]) => {
        cinemaConfig = config[0]
      })
    }
    //ONLINE MODE
    else {
      //get config from axios
      cinemaConfig = await utilities.getConfig()

      //get cinema id
      if (this.$route.params.cinemaId) {
        this.$store.state.cinemas = cinemaConfig.cinemas
        //get cinema id from url
        const cinemaId = this.$route.params.cinemaId as string
        this.getCinemaInfo(Number(cinemaId))
      }
    }

    //Set cinema config and redirect on multicinema
    if (!this.$route.params.cinemaId){
      this.setCinemaConfig(cinemaConfig)
    }
  },

  mounted() {
    //KioskMode
    if (this.$store.state.kioskMode) {

      //push to ticket pickup
      this.$router.push('/retiro')

      //set electron api
      const { electron } = window as any;

      //on new update
      electron.on('update_available', () => {
        const toast = this.$refs.toast as any
        toast.show("Hay una nueva actualización. Se inició la descarga.")
      })

      //on update downloaded
      electron.on('update_downloaded', () => {
        // show toast
        const toast = this.$refs.toast as CustomVue
        toast.show("Instalando nueva actualizacion. La aplicación se reiniciará.")

        // give time to read and restart
        setTimeout(() => {
          electron.send('update_install')
        }, 5000);
      })
    }
  },

  methods: {
    async getCinemaInfo(cinemaId: number) {
      //get cinema info
      const cinemaInfo = await utilities.getFromApi("/cinemas/" + cinemaId);

      if (cinemaInfo.data.status == "ok") {
        this.$store.commit("setCinemaInfo", cinemaInfo.data.data);
        this.loading = false
      } else {
        const toast = this.$refs.toast as any
        toast.show("Hubo un problema al cargar la información de este cine. Por favor, recargue la web.")
      }
    },

    setCinemaConfig(cinemaConfig: any) {
      //set header
      utilities.setHeader(cinemaConfig.title)

      //on multi cinema
      if (cinemaConfig.cinemas.length > 1) {
        this.$store.state.cinemas = cinemaConfig.cinemas
        this.$router.push('/seleccionar-complejo')
        this.$router.afterEach(() => {
          this.loading = false
        })
      }
      else {
        //set cinema info
        this.$store.commit("setCinemaInfo", cinemaConfig.cinemas[0])
        this.getCinemaInfo(this.$store.state.cinemaInfo.id)
      }
    },

    setDateTime() {
      const date = new Date();
      this.dateTime = {
        hours: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minutes:
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      };
    },

    openKioskMenu() {
      if (kioskMenuCounter < 4) {
        kioskMenuCounter++
      } else {
        // show kiosk menu
        const kioskMenu = this.$refs.kioskMenu as CustomVue
        kioskMenu.toggleMenu(true)

        // reset kiosk menu count
        kioskMenuCounter = 0
      }
    },

  }
});
</script>

<style>
* {
  font-family: 'Inter', sans-serif;
}

/*-- Transition --*/
.fade-enter-active,
.fade-leave-active {
  width: 100%;
}

.fade-enter-active {
  animation: enter .3s linear;
}

.fade-leave-active {
  opacity: 0;
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@keyframes leave {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}
</style>
